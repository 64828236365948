import {Injectable} from '@angular/core';
import {tap} from 'rxjs';
import {Page} from 'src/app/interfaces/backend/core/page.interface';
import {Pageable} from 'src/app/interfaces/backend/core/pageable.interface';
import {UserSessionDto} from 'src/app/interfaces/backend/dto/user-session-dto.interface';
import {RadiologyControllerService} from 'src/app/services/backend/radiology-controller.service';

@Injectable({providedIn: 'root'})
export class SessionsFacade {
    public dataPage: Page<UserSessionDto> | null = null;

    constructor(
        private radiologyControllerService: RadiologyControllerService
    ) {
    }

    public reset() {
        this.dataPage = null;
    }

    public loadData(userIds: number[], pageable: Pageable, fromCreateDate?: string, toCreateDate?: string) {
        this.loadDataLogic(userIds, pageable, fromCreateDate, toCreateDate);
    }

    public loadDataLogic(userIds: number[], pageable: Pageable, fromCreateDate?: string, toCreateDate?: string) {
        return this.getData(userIds, pageable, fromCreateDate, toCreateDate).pipe(
            tap(data => this.dataPage = data)
        );
    }

    public getData(userIds: number[], pageable: Pageable, fromCreateDate?: string, toCreateDate?: string) {
        return this.radiologyControllerService.getUserSessions(userIds, pageable, fromCreateDate, toCreateDate);
    }
}