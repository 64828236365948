import {SiteRoute} from 'src/app/enums/core/site-route.enum';
import {RadAiDictDto} from 'src/app/interfaces/backend/dto/rad-ai-dict-dto.interface';
import {RadCategoryDto} from 'src/app/interfaces/backend/dto/rad-category-dto.interface';
import {RadPatientDto} from 'src/app/interfaces/backend/dto/rad-patient-dto.interface';
import {
    RadTemplateCategoryDto,
    RadTemplateDto
} from 'src/app/interfaces/backend/dto/rad-templates-category-dto.interface';
import {RadiologyTaskDto} from 'src/app/interfaces/backend/dto/radiology-task-dto.interface';
import {UnitDto} from 'src/app/interfaces/backend/dto/unit-dto.interface';
import {UserDto} from 'src/app/interfaces/backend/dto/user-dto.interface';
import {BreadcrumbItem} from 'src/app/interfaces/core/breadcrumb-item.interface';
import {RouteSubjects} from 'src/app/interfaces/core/route-subjects.type';
import {SiteNode} from 'src/app/interfaces/core/site-node.interface';
import {getUserFullName} from 'src/app/utilities/format.util';

export const mapToBreadcrumbItem = (value: SiteNode, key?: SiteRoute) => {
    const result: BreadcrumbItem = {
        siteNodeId: value.siteNodeId,
        key
    };

    return result;
};

export const mapBreadcrumbLabelLogic = (data: RadiologyTaskDto |
    UnitDto |
    UserDto |
    RadTemplateDto |
    RadPatientDto |
    RadCategoryDto |
    RadTemplateCategoryDto |
    RadAiDictDto, key: SiteRoute) => {
    let label: string | undefined;
    switch (key) {
        case SiteRoute.P_RADIOLOGY_TASK_ID:
            label = (data as RadiologyTaskDto).id?.toString();
            break;

        case SiteRoute.P_UNIT_ID:
            label = (data as UnitDto).name;
            break;

        case SiteRoute.P_USER_ID:
            label = getUserFullName(data as UserDto);
            break;

        case SiteRoute.P_RAD_TEMPLATE_ID:
            label = (data as RadTemplateDto).id?.toString();
            break;

        case SiteRoute.P_PATIENT_ID:
            label = getUserFullName(data as RadPatientDto);
            break;

        case SiteRoute.P_RADIOLOGY_TASK_CATEGORY_ID:
            label = (data as RadCategoryDto).id?.toString();
            break;

        case SiteRoute.P_RADIOLOGY_TASK_DESCRIPTION_TEMPLATE_CATEGORY_ID:
            label = (data as RadTemplateCategoryDto).id?.toString();
            break;

        case SiteRoute.P_AI_ID:
            label = (data as RadAiDictDto).id?.toString();
            break;

        default:
            label = data.toString();
    }

    return label;
};

export const mapBreadcrumbLabel = (breadcrumbItem: BreadcrumbItem, routeSubjects: RouteSubjects) => {
    const {key} = breadcrumbItem;
    if (!key || !Object.keys(routeSubjects).includes(key)) return breadcrumbItem;

    const result: BreadcrumbItem = {
        ...breadcrumbItem,
        label: mapBreadcrumbLabelLogic(routeSubjects[key], key)
    };

    return result;
};

export const mapBreadcrumbsLabels = (breadcrumbs: BreadcrumbItem[], routeSubjects: RouteSubjects) => {
    const result: BreadcrumbItem[] = breadcrumbs.map(entry => mapBreadcrumbLabel(entry, routeSubjects));
    return result;
};