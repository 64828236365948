import {Injectable, signal, WritableSignal} from '@angular/core';
import {Observable} from 'rxjs';
import {AdditionalElement} from 'src/app/interfaces/core/additional-element.interface';
import {MenuItemExt} from 'src/app/interfaces/core/prime-ng/menu-item-ext.interface';
import {TranslationService} from 'src/app/services/core/translation.service';

@Injectable({
    providedIn: 'root'
})
export class HelperTopbarService {
    public helperTopbarElements: AdditionalElement[] = [];
    public activeViewSignal: WritableSignal<string | null> = signal(null);
    public helperTopbarMenuItems$: Observable<MenuItemExt[]>;

    constructor(
        private translationService: TranslationService
    ) {
        this.helperTopbarMenuItems$ = this.translationService.translateMenuItems([]);
    }

    public setHelperTopbarMenuItems(value?: MenuItemExt[]) {
        this.helperTopbarMenuItems$ = this.translationService.translateMenuItems(value ?? []);
    }

    public reset() {
        this.helperTopbarElements = [];
        this.activeViewSignal.set(null);
        this.setHelperTopbarMenuItems([]);
        this.setHelperTopbarMenuItems();
    }

    public changeView(view: string) {
        this.activeViewSignal.set(view);
    }
}