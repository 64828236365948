import {Injectable} from '@angular/core';
import {Observable, Subscription, tap} from 'rxjs';
import {AIFacade} from 'src/app/facades/ai.facade';
import {BadgesFacade} from 'src/app/facades/badges.facade';
import {PatientsFacade} from 'src/app/facades/patients.facade';
import {ProceduresRatesFacade} from 'src/app/facades/procedures-rates.facade';
import {RadiologyTaskAIResultsFacade} from 'src/app/facades/radiology-task-ai-results.facade';
import {RadiologyTaskCategoriesFacade} from 'src/app/facades/radiology-task-categories.facade';
import {
    RadiologyTaskDescriptionTemplatesCategoriesFacade
} from 'src/app/facades/radiology-task-description-templates-categories.facade';
import {RadiologyTaskDescriptionsTemplatesFacade} from 'src/app/facades/radiology-task-descriptions-templates.facade';
import {RadiologyTaskHistoryFacade} from 'src/app/facades/radiology-task-history.facade';
import {RadiologyTaskNotesFacade} from 'src/app/facades/radiology-task-notes-facade.service';
import {RadiologyTasksFacade} from 'src/app/facades/radiology-tasks.facade';
import {ReportsFacade} from 'src/app/facades/reports.facade';
import {SessionsFacade} from 'src/app/facades/sessions.facade';
import {SettlementsFacade} from 'src/app/facades/settlements.facade';
import {StatisticFacade} from 'src/app/facades/statistic.facade';
import {UnitsFacade} from 'src/app/facades/units.facade';
import {UsersCalendarFacade} from 'src/app/facades/users-calendar.facade';
import {UsersFacade} from 'src/app/facades/users.facade';
import {BottombarService} from 'src/app/services/core/bottombar.service';
import {CoreService} from 'src/app/services/core/core.service';
import {HelperTopbarService} from 'src/app/services/core/helper-topbar.service';
import {KeyboardShortcutsService} from 'src/app/services/core/keyboard-shortcuts.service';

@Injectable({
    providedIn: 'root'
})
export class DataCleanerService {
    private subscriptions: Subscription = new Subscription();
    private navigationStart$: Observable<boolean>;

    constructor(
        private coreService: CoreService,
        private bottombarService: BottombarService,
        private helperTopbarService: HelperTopbarService,
        private usersFacade: UsersFacade,
        private unitsFacade: UnitsFacade,
        private radiologyTasksFacade: RadiologyTasksFacade,
        private radiologyTaskDescriptionsTemplatesFacade: RadiologyTaskDescriptionsTemplatesFacade,
        private statisticFacade: StatisticFacade,
        private settlementsFacade: SettlementsFacade,
        private sessionsFacade: SessionsFacade,
        private proceduresRatesFacade: ProceduresRatesFacade,
        private descriptionsNotesFacade: RadiologyTaskNotesFacade,
        private usersCalendarFacade: UsersCalendarFacade,
        private keyboardShortcutsService: KeyboardShortcutsService,
        private reportsFacade: ReportsFacade,
        private patientsFacade: PatientsFacade,
        private radiologyTaskHistoryFacade: RadiologyTaskHistoryFacade,
        private badgesFacade: BadgesFacade,
        private radiologyTaskCategoriesFacade: RadiologyTaskCategoriesFacade,
        private radiologyTaskDescriptionTemplatesCategoriesFacade: RadiologyTaskDescriptionTemplatesCategoriesFacade,
        private radiologyTaskAIResultsFacade: RadiologyTaskAIResultsFacade,
        private aiFacade: AIFacade
    ) {
        this.navigationStart$ = this.coreService.navigationStart$;
        this.subscriptions.add(this.watchForNavigationStart());
    }

    private watchForNavigationStart() {
        return this.navigationStart$.pipe(
            tap(() => this.resetAllData())
        ).subscribe();
    }

    public resetAllData() {
        this.bottombarService.reset();
        this.helperTopbarService.reset();
        this.usersFacade.reset();
        this.unitsFacade.reset();
        this.radiologyTasksFacade.reset();
        this.radiologyTaskDescriptionsTemplatesFacade.reset();
        this.statisticFacade.reset();
        this.settlementsFacade.reset();
        this.sessionsFacade.reset();
        this.proceduresRatesFacade.reset();
        this.descriptionsNotesFacade.reset();
        this.usersCalendarFacade.reset();
        this.keyboardShortcutsService.reset();
        this.reportsFacade.reset();
        this.patientsFacade.reset();
        this.radiologyTaskHistoryFacade.reset();
        this.badgesFacade.reset();
        this.radiologyTaskCategoriesFacade.reset();
        this.radiologyTaskDescriptionTemplatesCategoriesFacade.reset();
        this.radiologyTaskAIResultsFacade.reset();
        this.aiFacade.reset();
    }
}