import {Injectable, Injector} from '@angular/core';
import {filter, map, Observable, of, tap} from 'rxjs';
import {SiteRoute} from 'src/app/enums/core/site-route.enum';
import {DictionariesFacade} from 'src/app/facades/dictionaries.facade';
import {REQUESTS_HASH} from 'src/app/helpers/core/misc.helper';
import {RadCategoryDto} from 'src/app/interfaces/backend/dto/rad-category-dto.interface';
import {RadiologyControllerService} from 'src/app/services/backend/radiology-controller.service';
import {displayError, tapDisplaySuccess} from 'src/app/utilities/toast.util';

@Injectable({providedIn: 'root'})
export class RadiologyTaskCategoriesFacade {
    public data: RadCategoryDto[] = [];
    public activeItem: RadCategoryDto | null = null;

    constructor(
        private dictionariesFacade: DictionariesFacade,
        private radiologyControllerService: RadiologyControllerService,
        private injector: Injector
    ) {
    }

    public reset() {
        this.data = [];
        this.activeItem = null;
    }

    public loadData() {
        this.loadDataLogic().subscribe();
    }

    public loadDataLogic() {
        return this.getData().pipe(
            tap(data => this.data = data)
        );
    }

    public getData() {
        return this.dictionariesFacade.getRadiologyTaskCategoriesList();
    }

    public loadItem(categoryId: number) {
        this.getItem(categoryId).subscribe(data => this.activeItem = data);
    }

    public getItem(categoryId: number) {
        return this.radiologyControllerService.getRadiologyTaskCategory(categoryId);
    }

    public create(radCategoryDto: RadCategoryDto, callback?: () => void) {
        this.radiologyControllerService.createRadiologyTaskCategory(radCategoryDto).pipe(
            tap(() => callback?.()),
            tapDisplaySuccess(this.injector)
        ).subscribe();
    }

    public update(radCategoryDto: RadCategoryDto, callback: () => void) {
        const fn = async (id: number) => {
            return this.radiologyControllerService.updateRadiologyTaskCategory(id, radCategoryDto).pipe(
                tap(data => {
                    this.activeItem = data;
                    callback();
                }),
                tapDisplaySuccess(this.injector)
            ).subscribe();
        };

        this.getItemId(fn);
    }

    public getItemId<T>(fn: (id: number) => T) {
        const id = this.activeItem?.id;
        if (!id) {
            displayError(this.injector);
            return;
        }

        return fn(id);
    }

    public handleSubject(param?: string, requestsHash?: string) {
        if (!param) return of(null);

        const categoryId = Number.parseInt(param);
        const request: Observable<RadCategoryDto | null> = param === SiteRoute.CREATE ?
            of(null) :
            this.getItem(categoryId);
        return request.pipe(
            filter(() => requestsHash === REQUESTS_HASH),
            tap(data => this.activeItem = data)
        );
    }
}