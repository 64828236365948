import {Injectable, Injector, signal, WritableSignal} from '@angular/core';
import {tap} from 'rxjs';
import {Role} from 'src/app/enums/backend/role.enum';
import {Page} from 'src/app/interfaces/backend/core/page.interface';
import {Pageable} from 'src/app/interfaces/backend/core/pageable.interface';
import {RadSettlementFilterDto} from 'src/app/interfaces/backend/dto/rad-settlement-filter-dto.interface';
import {RadUserSettlementDto} from 'src/app/interfaces/backend/dto/rad-user-settlement-dto.interface';
import {RadiologyControllerService} from 'src/app/services/backend/radiology-controller.service';
import {displaySuccess} from 'src/app/utilities/toast.util';

@Injectable({providedIn: 'root'})
export class SettlementsFacade {
    public dataPageSignal: WritableSignal<Page<RadUserSettlementDto> | null> = signal(null);

    constructor(
        private radiologyControllerService: RadiologyControllerService,
        private injector: Injector
    ) {
    }

    public reset() {
        this.dataPageSignal.set(null);
    }

    public loadData(role: Role, subjectId: number, pageable: Pageable = {}, filter: RadSettlementFilterDto) {
        this.loadDataLogic(role, subjectId, pageable, filter).subscribe();
    }

    public loadDataLogic(role: Role, subjectId: number, pageable: Pageable = {}, filter: RadSettlementFilterDto) {
        return this.getData(role, subjectId, pageable, filter).pipe(
            tap(data => this.dataPageSignal.set(data))
        );
    }

    public getData(role: Role, subjectId: number, pageable: Pageable = {}, filter: RadSettlementFilterDto) {
        return role === Role.INSTITUTION ?
            this.radiologyControllerService.getUnitSettlements(subjectId, pageable, filter) :
            this.radiologyControllerService.getUserSettlements(subjectId, pageable, filter);
    }

    public create(userId: number, radUserSettlementDto: RadUserSettlementDto, callback?: () => void) {
        this.radiologyControllerService.createSettlement(userId, radUserSettlementDto).subscribe((data) => {
            displaySuccess(this.injector);
            callback?.();
        });
    }

    public getSettlementsSummarize(role: Role, subjectId: number) {
        return role === Role.INSTITUTION ?
            this.radiologyControllerService.getUnitSettlementsSummarize(subjectId) :
            this.radiologyControllerService.getUserSettlementsSummarize(subjectId);
    }
}